/* Constantes que nos indica que el endpoints tiene un mensaje */
const SUCCESS_SERVER = {
  SUCCES_SERVER_DEFAULT: "SUCCES_SERVER_DEFAULT" /*valor por defecto  */,
  SUCCES_SERVER_OK:
    "SUCCES_SERVER_OK" /*indica que el endpoints no tiene mensajes */,
  SUCCES_SERVER_INFO:
    "SUCCES_SERVER_INFO" /*indica que el endpoints tiene un mensaje para el usuario ejm:Acceso denegado--> al ingresar una contraseña invalida */,
  SUCCES_SERVER_ERROR:
    "SUCCES_SERVER_ERROR" /*indica que se tiene un error interno, la cual se debe hacer el seguimiento para corregir el error */,
  SUCCES_SERVER_WARRING:
    "SUCCES_SERVER_WARRING" /*indica que se tiene un error interno, la cual se debe hacer el seguimiento para corregir el error */,
  SUCCES_SERVER_EXPIRE:
    "SUCCES_SERVER_EXPIRE" /*Validacion de token */,

};

/*Constante que nos indica que el endpoints   */
const HttpStatus = {
  HttpStatus_OK: 200 /*retorno datos sin ningun mensaje de validacion */,
};
/*Constantes del componente de loginCliente.js y app.js */
const LOGGIN = {
  LOGGIN: "LOGGIN",
  LOGOUT: "LOGOUT",
};
/*Constante de tipo de ubigeo la cual el endpoint responde a estos valores 
para devolver los listados de departamentos,provincias o distritos
 */
const UbigeoTipo = {
  DEPARTAMENTO: 0,
  PROVINCIA: 1,
  DISTRITO: 2,
};
/*Variables para identificar que accion esta realizando */
const CRUD = {
  INSERT: {
    codigoCrud: 1,
    descripcion: "INSERT",
    estado: "Guardar",
    estadoRequest: "REGISTRANDO...",
  },
  UPDATE: {
    codigoCrud: 2,
    descripcion: "UPDATE",
    estado: "Actualizar",
    estadoRequest: "ACTUALINZANDO...",
  },
  DELETE: {
    codigoCrud: 3,
    descripcion: "DELETE",
    estado: "Eliminar",
    estadoRequest: "ELIMINANDO...",
  },
  SELECT: {
    codigoCrud: 4,
    descripcion: "SELECT",
    estado: "Consultar",
    estadoRequest: "CONSULTANDO...",
  },
};
/*Constate para evaluar si el componente esta cargando para ejecutar dispacher sin render infinitos */
const PAGE = {
  LOADING: "LOADING" /*Se esta cargando */,
  RENDERING: "RENDERING" /* Cuando se realizo el renderizado */,
  _INIT: "_INIT" /*Por defecto */,
};

/*Variables para identificar el tipo de moneda */
const Moneda = {
  DOLARES: {
    numCodigoMoneda: "1",
    vchDescripcion: "Dólares",
    vchAbreviatura: "D",
    vchSimbolo: "$",
    codigoIso4217: "USD",
  },
  SOLES: {
    numCodigoMoneda: "2",
    vchDescripcion: "Soles",
    vchAbreviatura: "S",
    vchSimbolo: "S/",
    codigoIso4217: "PEN",
  },
};
/*Variables de localStoreEnum */
const localStoreEnum = {
  COTIZACION: "COTIZACION",
  USUARIO: "USUARIO",
  ISLOGIN: "ISLOGIN",
  TOKEN: "TOKEN",
};
const statusSyncCotizacion = {
  SYNCRONIZA: "SYNCRONIZA",
  UNSYNCHRONIZED: "UNSYNCHRONIZED",
};
/*Tipo de actualizacion que se realiza al detalle de la cotizacion
ADICIONAR=Que al valor registrado en la tabla le aumentamos el valor que estamos pasando[BD=1 Parametro=2--> Cantidad Final=3]
ACTUALIZAR=Que el valor pasado como parametro sera el que se guarda[BD=5 Parametro=2--> Cantidad Final=2]
*/
const tipoActualizacionCotizacionDetalle = {
  ADICIONAR: "ADICIONAR",
  ACTUALIZAR: "ACTUALIZAR",
};
const InfoCondicionCompra = {
  EMISION:
    <p><i className="fa fa-shield-p"></i>Emitimos facturas en dólares. Si paga en una moneda diferente se realizará la conversión a USD,según al tipo de cambio indicado en la parte superior de la página.</p>,
  TRANSPORTE:
    <p><i className="fa fa-truck" aria-hidden="true"></i>Envíos en los siguientes 10 días; verifica que tu calle no se encuentre en las zonas peligrosas.</p>,
  DEVOLUCIONES:
    <p><i className="fa fa-exchange" aria-hidden="true"></i>No se aceptan devoluciones a menos que el producto tenga falla de fábrica.</p>,
  STOCK: (
    <p>
      <i>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="box-open"
          class="svg-inline--fa fa-box-open fa-w-20"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path
            fill="currentColor"
            d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"
          ></path>
        </svg>
      </i>
      Mientras procesamos su pedido y/o compra, es posible que el stock
      publicado en nuestra página se puede agotar, de ser asi la operación será
      cancelada. De ocurrir esto y la transacción se efectuó, nos comprometemos
      a realizar el extorno del dinero a su Tarjeta del Banco o emitir una Nota
      de crédito para que Ud. pueda utilizarlo en compras futuras.
      <br />
     
      Se dá por entendido que reconoce y acepta estar debidamente informado de
      los alcances y limitaciones de las transacciones que realice por nuestro
      portal de comercio electrónico, no existiendo lugar a reclamo si las
      condiciones descritas se presentan durante el procesamiento de su compra.
    </p>
  ),
};
/* Secuencia de pago */
const PagoMenu = {
  PERSONALES: { index: 1 },
  ENVIO: { index: 2 },
  PASARELA: { index: 3 },
};
/*Para la visualizacion de los productos*/
const displayLista = {
  RESUMEN: "RESUMEN",
  DETALLE: "DETALLE",
};

/*Modos de envio   */
const MetodoEnvio = {
  RecojoAlmacen: {
    numTipoMetodoEnvio: 0,
    codigo: "RecojoAlmacen",
    descripcion: "Recojo en Bodega",
    //direccion: "Calle Libertad 386 - San Miguel",
    direccion: "Próximamente habilitada",
    icons: "almacen.png",
    //precio: "Gratis",
    precio: "",
  },
  EnvioRegular: {
    numTipoMetodoEnvio: 1,
    codigo: "EnvioRegular",
    descripcion: "Envio Regular",
    direccion: "Entrega en los siguientes 10 dias",
    icons: "camion.png",
    precio: "",
  },
};
const statusMetodoEnvio = {
  DEFAULT: "DEFAULT",
  ACTUALIZADO: "ACTUALIZADO",
  ERROR_ZONA_INCONRRECTA: "ERROR_ZONA_INCONRRECTA",
  ERROR_SUPERA_CARGA: "ERROR_SUPERA_CARGA",
};
/*
option value="1">DNI</option>
            <option value="2">RUC</option>
            <option value="3">Carnet de extranjeria</option>
            <option value="4">Pasaporte</option>

*/
const TipoDocumento = {
  DEFAULT: {
    numtipocliente: 0,
    vchdescripcion: "-- Seleccione --",
    longitud: 0,
  },
  DNI: { numtipocliente: 1, vchdescripcion: "DNI", longitud: 8 },
  RUC: { numtipocliente: 2, vchdescripcion: "RUC", longitud: 11 },
  CARNET_EXT: {
    numtipocliente: 3,
    vchdescripcion: "Carnet de extranjeria",
    longitud: 12,
  },
  PASAPORTE: { numtipocliente: 4, vchdescripcion: "Pasaporte", longitud: 12 },
};

const lstMarcas = [
  {
    codigoMarca: 0,
    decripcion: "All",
    srcImage: "",
    chrcodigofamilia: null,
    classMarca: "class-marca-all",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/banner/SONAX.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/banner/HYUNDAI.jpg" },
      { codigoCarrucel: 3, srcImage: "/marcas/banner/BOSCH.jpg" },
      { codigoCarrucel: 4, srcImage: "/marcas/banner/TOKICO.jpg" },
      { codigoCarrucel: 5, srcImage: "/marcas/banner/SPARCO.jpg" },
      { codigoCarrucel: 6, srcImage: "/marcas/banner/SPORTRACK.jpg" },
    ],
    lstBannerPromocion: [
      {
        codigoBanner: 1,
        descripcion: "banner promocion 1",
        srcImage: "/marcas/banner/promocion/1.png",
      },
      {
        codigoBanner: 2,
        descripcion: "banner promocion 2",
        srcImage: "/marcas/banner/promocion/2.png",
      },
      {
        codigoBanner: 3,
        descripcion: "banner promocion 3",
        srcImage: "/marcas/banner/promocion/3.png",
      },
      {
        codigoBanner: 4,
        descripcion: "banner promocion 4",
        srcImage: "/marcas/banner/promocion/4.png",
      },
    ],
  },
  {
    codigoMarca: 1,
    decripcion: "BOSCH",
    srcImage: "/marcas/bosch.jpg",
    chrcodigofamilia: "110C",
    classMarca: "class-marca-bosch",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/bosch/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/bosch/2.jpg" },
      { codigoCarrucel: 3, srcImage: "/marcas/bosch/3.jpg" },
      { codigoCarrucel: 4, srcImage: "/marcas/bosch/4.jpg" },
      { codigoCarrucel: 5, srcImage: "/marcas/bosch/5.jpg" },
      { codigoCarrucel: 6, srcImage: "/marcas/bosch/6.jpg" },
      { codigoCarrucel: 7, srcImage: "/marcas/bosch/7.jpg" },
      { codigoCarrucel: 8, srcImage: "/marcas/bosch/8.jpg" },
    ],
    lstBannerPromocion: [
      {
        codigoBanner: 1,
        descripcion: "banner promocion 1",
        srcImage: "/marcas/bosch/promocion/1.png",
      },
      {
        codigoBanner: 2,
        descripcion: "banner promocion 2",
        srcImage: "/marcas/bosch/promocion/2.png",
      },
      {
        codigoBanner: 3,
        descripcion: "banner promocion 3",
        srcImage: "/marcas/bosch/promocion/3.png",
      },
      {
        codigoBanner: 4,
        descripcion: "banner promocion 4",
        srcImage: "/marcas/bosch/promocion/4.png",
      },
    ],
  },
  {
    codigoMarca: 6,
    decripcion: "TOKICO",
    srcImage: "/marcas/tokico.jpg",
    chrcodigofamilia: "110H",
    classMarca: "class-marca-tokico",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/tokico/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/tokico/2.jpg" },
    ],
    lstBannerPromocion: [],
  },

  {
    codigoMarca: 3,
    decripcion: "HYUNDAI",
    srcImage: "/marcas/hyundai.jpg",
    chrcodigofamilia: "110G",
    classMarca: "class-marca-hyundai",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/hyundai/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/hyundai/2.jpg" },
      { codigoCarrucel: 3, srcImage: "/marcas/hyundai/3.jpg" },
    ],
    lstBannerPromocion: [],
  },
  {
    codigoMarca: 4,
    decripcion: "SONAX",
    srcImage: "/marcas/sonax.jpg",
    chrcodigofamilia: "110D",
    classMarca: "class-marca-sonax",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/sonax/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/sonax/2.jpg" },
      { codigoCarrucel: 3, srcImage: "/marcas/sonax/3.jpg" },
      { codigoCarrucel: 4, srcImage: "/marcas/sonax/4.jpg" },
    ],
    lstBannerPromocion: [
      {
        codigoBanner: 1,
        descripcion: "banner promocion 1",
        srcImage: "/marcas/sonax/promocion/1.png",
      },
      {
        codigoBanner: 2,
        descripcion: "banner promocion 2",
        srcImage: "/marcas/sonax/promocion/2.png",
      },
      {
        codigoBanner: 3,
        descripcion: "banner promocion 3",
        srcImage: "/marcas/sonax/promocion/3.png",
      },
      {
        codigoBanner: 4,
        descripcion: "banner promocion 4",
        srcImage: "/marcas/sonax/promocion/4.png",
      },
    ],
  },
  {
    codigoMarca: 7,
    decripcion: "SPORTRACK",
    srcImage: "/marcas/sportrack.jpg",
    chrcodigofamilia: "110F",
    classMarca: "class-marca-sportrack",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/sportrack/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/sportrack/2.jpg" },
    ],
    lstBannerPromocion: [
      {
        codigoBanner: 1,
        descripcion: "banner promocion 1",
        srcImage: "/marcas/sportrack/promocion/1.png",
      },
      {
        codigoBanner: 2,
        descripcion: "banner promocion 2",
        srcImage: "/marcas/sportrack/promocion/2.png",
      },
      {
        codigoBanner: 3,
        descripcion: "banner promocion 3",
        srcImage: "/marcas/sportrack/promocion/3.png",
      },
      {
        codigoBanner: 4,
        descripcion: "banner promocion 4",
        srcImage: "/marcas/sportrack/promocion/4.png",
      },
    ],
  },
  {
    codigoMarca: 5,
    decripcion: "SPARCO",
    srcImage: "/marcas/sparco.jpg",
    chrcodigofamilia: "110E",
    classMarca: "class-marca-sparco",
    lstCarrucel: [
      { codigoCarrucel: 1, srcImage: "/marcas/sparco/1.jpg" },
      { codigoCarrucel: 2, srcImage: "/marcas/sparco/2.jpg" },
      { codigoCarrucel: 3, srcImage: "/marcas/sparco/3.jpg" },
      { codigoCarrucel: 4, srcImage: "/marcas/sparco/4.jpg" },
    ],
    lstBannerPromocion: [
      {
        codigoBanner: 1,
        descripcion: "banner promocion 1",
        srcImage: "/marcas/sparco/promocion/1.png",
      },
      {
        codigoBanner: 2,
        descripcion: "banner promocion 2",
        srcImage: "/marcas/sparco/promocion/2.png",
      },
      {
        codigoBanner: 3,
        descripcion: "banner promocion 3",
        srcImage: "/marcas/sparco/promocion/3.png",
      },
      {
        codigoBanner: 4,
        descripcion: "banner promocion 4",
        srcImage: "/marcas/sparco/promocion/4.png",
      },
    ],
  },


  {
    codigoMarca: 2,
    decripcion: "ECCO",
    srcImage: "/marcas/ecco.jpg",
    chrcodigofamilia: "110I",
    classMarca: "class-marca-ecco",
    lstCarrucel: [{ codigoCarrucel: 1, srcImage: "/marcas/ecco/1.jpg" }],
    lstBannerPromocion: [],
  },
];

const FilterProducto = {
  FILTER_ALL: "FILTER_ALL",
  FILTER_CODIGO: "FILTER_CODIGO",
  FILTER_DESTACADO: "FILTER_DESTACADO",
  FILTER_DESTACADO_MARCA: "FILTER_DESTACADO_MARCA",
  FILTER_RECOMENDADO: "FILTER_RECOMENDADO",
  FILTER_OFERTA: "FILTER_OFERTA",
  FILTER_REMATE: "FILTER_REMATE",
  FILTER_ALL_FIND: "FILTER_ALL_FIND",
  FILTER_SEARCH: "FILTER_SEARCH",
};
const FilterSubFamilia = {
  FILTER_SUBFAMILIA_ALL: "FILTER_SUBFAMILIA_ALL",
  FILTER_SUBFAMILIA_LIST: "FILTER_SUBFAMILIA_LIST",
};
const filterOrder = {
  FilterDescDescripcion: "FilterDescDescripcion",
  FilterAscDescripcion: "FilterAscDescripcion",
  FilterDescPrecio: "FilterDescPrecio",
  FilterAscPrecio: 'FilterAscPrecio',
  FilterConImagen: 'FilterConImagen',
};
const nav_banner = {
  /* 1:Se visualizara 0:No se mostrara */
  status: 1,
  text: "Página en Modo de Prueba. Próximamente estará habilitada al 100%"
}
const tipoDireccion = {
  DESPACHO: "Dirección de Despacho",
  FACTURACION: "Dirección de Facturación"
};
//DESARROLLO
//const homepage = "eanetautoparts/desarrollo";
//BETA
const homepage = "eanetautoparts";
const chrRol = {
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_ANONIMO: "ROLE_ANONIMO"
};

const FilterCorreo = {
  FILTER_ALL:'FILTER_ALL', 
  FILTER_DESTINO_OC:'FILTER_DESTINO_OC',
};
const Empresa={
  ruc:'20604770476',
  razonSocial:'EANET GLOBAL S.A.C.',
  nombreComercial:'eanet auto parts',
  urlTienda:'https://eanetautoparts.pe/eanetautoparts/shop',
  direccion:'AV. república de Panamá Nro. 4275',
  correo:'consultas@eanetautoparts.pe',
  abreviaturaSucursal:'CL386'
}
export {
  SUCCESS_SERVER,
  HttpStatus,
  LOGGIN,
  UbigeoTipo,
  TipoDocumento,
  CRUD,
  PAGE,
  lstMarcas,
  Moneda,
  localStoreEnum,
  statusSyncCotizacion,
  tipoActualizacionCotizacionDetalle,
  InfoCondicionCompra,
  PagoMenu,
  MetodoEnvio,
  statusMetodoEnvio,
  displayLista,
  FilterProducto,
  FilterSubFamilia,
  filterOrder, nav_banner,
  tipoDireccion,
  homepage, chrRol,
  FilterCorreo,
  Empresa
};

